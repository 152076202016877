// Variables

// Colors
// Gray and brand colors for use across The iDea Template.
$gray-dark: #343434 !default;
$gray: #666666 !default;
$gray-light: #999999 !default;
$gray-lighter: #cdcdcd !default;
$ghost-white: #fafafa !default;
$white: #ffffff !default;
$black: #000000 !default;

// Base Colors of Light Blue Skin.
$base-color: #e84c3d !default;
$base-color-hover: #cd3c2e !default;

// Background color for `<body>`.
$body-bg: #ffffff !default;

// Global text color on `<body>`.
$text-color: $gray !default;

// Global textual link color.
$link-color: $base-color !default;

// Link hover color.
$link-color-hover: $base-color-hover !default;

// Header Top and Header
$light-header-top-background: #f1f1f1 !default;
$light-header-top-border-bottom: #dadada !default;
$light-header-background: #ffffff !default;
$light-header-border-bottom: #eaeaea !default;
$dark-header-top-background: #27272a !default;
$dark-header-top-border-bottom: #000000 !default;
$dark-header-background: #36373C !default;
$dark-header-border-top: #515151 !default;

// Backgrounds colors of sections
$dark-background: #000000 !default;
$gray-background: #f1f1f1 !default;
$gray-background: #F7F7F7;
$light-gray-background: #fafafa !default;
$light-gray-section-border-color: #eaeaea !default;
$default-background: $base-color !default;

// Footer and Subfooter
$dark-footer-background: #262626 !default;
$dark-footer-border-top: #3a3a3a !default;
$dark-footer-border-bottom: #333333 !default;
$dark-subfooter-background: #000000 !default;
$light-footer-background: #f7f7f7 !default;
$light-footer-border-top: #dddddd !default;
$light-footer-border-bottom: #d1d1d1 !default;
$light-subfooter-background: #e7e7e7 !default;

// Typography
// Font, line-height, and color for body text, headings, and more.
// $font-family-open-sans: 'Open Sans', sans-serif !default;
// $font-family-helvetica: 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
// $font-family-pt-serif: 'PT Serif', serif !default;
// $font-family-serif: Georgia, "Times New Roman", Times, serif !default;
// $font-family-base: $font-family-open-sans !default;


$font-family-open-sans: 'Roboto', sans-serif !default;
$font-family-helvetica: 'Roboto', Helvetica, Arial, sans-serif !default;
$font-family-pt-serif: 'Roboto', serif !default;
$font-family-serif: 'Roboto', "Times New Roman", Times, serif !default;
$font-family-base: $font-family-open-sans !default;

$font-size-base: 15px !default;
// @media only screen and (min-width:992px) {
//   $font-size-base: 18px !default;
// }
$font-size-h1: floor(($font-size-base * 2.55)) !default; // 38px
$font-size-h2: floor(($font-size-base * 1.87)) !default; // 28px
$font-size-h3: floor(($font-size-base * 1.47)) !default; // 22px
$font-size-h4: floor(($font-size-base * 1.2)) !default; // 18px
$font-size-h5: floor(($font-size-base * 1.067)) !default; // 16px


$font-size-h1: floor(($font-size-base * 3.2)); // 38px
$font-size-h2: floor(($font-size-base * 2.55)) ; //
$font-size-h3: floor(($font-size-base * 2.1)); // 38px
$font-size-h4: floor(($font-size-base * 1.6)); //
$font-size-h5: floor(($font-size-base * 1.4)); // 28px

// Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.5 !default;

// Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // 22px

// By default, this inherits from the `<body>`.
$headings-font-family: $font-family-open-sans !default;
$headings-line-height: 1.2 !default;

// Social Icons Colors
$twitter: #55acee !default;
$skype: #00aff0 !default;
$facebook: #3b5998 !default;
$linkedin: #0976b4 !default;
$googleplus: #dd4b39 !default;
$youtube: #b31217 !default;
$flickr: #ff0084 !default;
$pinterest: #cb2027 !default;
$instagram: #517fa4 !default;
$vimeo: #aad450 !default;
$tumblr: #32506d !default;
$soundcloud: #ff3a00 !default;
$foursquare: #0072b1 !default;
$dribbble: #ea4c89 !default;
$behance: #053eff !default;
$vine: #00a478 !default;
$stumbleupon: #f74425 !default;
