/* --------------------------------

Primary style

// -------------------------------- */
// html * {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
//
// *,
// *:after,
// *:before {
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
// }
//
// body {
//   font-size: 100%;
// }
//
// a {
//   text-decoration: none;
// }
//
// img {
//   max-width: 100%;
// }

/* --------------------------------

Modules - reusable parts of our design

-------------------------------- */
.frst-container {

  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 15px;
}

.frst-container::after {

  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

/* --------------------------------

Main components

-------------------------------- */
.frst-timeline {
  position: relative;
}

.frst-right-align {
  text-align: right;
}

/*.frst-date-opposite{
padding: 0;
}*/


.frst-timeline-block {
  position: relative;
  min-height: 16px;
  padding-left: 35px;
  padding-right: 35px;
}
.frst-timeline-block::before {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}
.frst-timeline-block:last-child::before{
  display: none;
}
.frst-left-align .frst-timeline-block::before {
}

.frst-right-align .frst-timeline-block::before {
  right: 0;
  left: auto !important;
  margin-left: 0 !important;
}
.frst-timeline-block::after {
  content: "";
  display: table;
  clear: both;
}

.frst-timeline-img {
  position: absolute;
  min-width: 1px;
  min-height: 1px;
  left: 0;
  top: 0;
}
.frst-timeline-img i{
  display: block;
}

.frst-right-align .frst-timeline-img {
  margin-left: 0 !important;
  left: auto !important;
  right: 0;
}
.frst-last-empty-item .frst-timeline-img span{
  vertical-align: top;
}

.frst-timeline-content-inner {
  position: relative;
}

.frst-date {
  display: block;
}

.cssanimations .frst-timeline-content.is-hidden {
  visibility: hidden;
}

.cssanimations .frst-timeline-content.animated {
  visibility: visible;
}

.frst-labels span{
  display: inline-block;
}

.frst-labels{
  padding: 20px 0;
}
.frst-labels.frst-start-label{
  padding-top: 0 !important;
}
.frst-labels.frst-end-label{
  margin-bottom: 0  !important;
}
.turqoise{
      background: #00A79D !important;
}
.light-blue{
      background: #27AAE2 !important;
}
.blue{
      background: #1D75BD !important;
}
.violette{
      background: #2B3991 !important;
}
.dark-violette{
      background: #272264 !important;
}
/*.aqua-blue{
  background: #36ACB6 !important;
}
.red{
  background: #C84028 !important;
}
.orange{
  background: #EB8D23 !important;
}
.light-green{
  background: #C2C84A !important;
}*/





@media only screen and (min-width:679px) {
  .frst-date-opposite {
    margin-left: 190px;
  }
  .frst-left-align.frst-date-opposite {
    margin-left: 190px;
  }

  .frst-right-align.frst-date-opposite {
    margin-right: 190px;
    margin-left: 0;
    text-align: right;
  }
  .frst-date-opposite.frst-alternate{
    margin: 0;
  }


  .frst-timeline.frst-alternate.frst-left-align .frst-timeline-content,
  .frst-timeline.frst-date-opposite.frst-left-align .frst-timeline-content,
  .frst-timeline.frst-alternate.frst-right-align .frst-timeline-content,
  .frst-timeline.frst-date-opposite.frst-right-align .frst-timeline-content {
    width: 100%;
  }

  .frst-alternate .frst-timeline-block::before {
    left: 50%;
  }

  .frst-alternate .frst-timeline-img {
    left: 50% !important;
  }

  .frst-alternate .frst-timeline-block.frst-even-item .frst-timeline-content {
    float: right;
  }
  .frst-alternate .frst-timeline-block.frst-odd-item .frst-timeline-content {
    float: left;
  }

  .frst-date-opposite .frst-date {
    position: absolute;
    top: 0;
    text-align: right;
  }

  .frst-alternate .frst-timeline-block.frst-odd-item .frst-timeline-content,
  .frst-alternate.frst-date-opposite .frst-timeline-block.frst-even-item .frst-date,
  .frst-date-opposite.frst-left-align .frst-date,
  .frst-right-align {
    text-align: right;
  }

  .frst-alternate.frst-date-opposite .frst-timeline-block.frst-odd-item .frst-timeline-content .frst-date,
  .frst-date-opposite.frst-right-align .frst-date {
    text-align: left;
  }
  .frst-alternate .frst-timeline-label-block{
    text-align: center;
  }
  .frst-alternate .frst-timeline-label-block .frst-labels span{
    left: 0;
    right: 0;
  }

}

@media only screen and (max-width:678px) {
  .frst-timeline{
    margin-left: 20px;
  }
  .frst-timeline.frst-right-align,
  .frst-timeline.frst-responsive-right {
    margin-right: 20px;
    margin-left: 0;
  }
  .frst-responsive-right .frst-timeline-block::before {
    right: 0;
    left: auto !important;
    margin-left: 0 !important;
  }
  .frst-responsive-right {
    text-align: right;
  }
  .frst-responsive-right .frst-timeline-img {
    margin-left: 0 !important;
    left: auto !important;
    right: 0;
  }
  .frst-date{
    margin-bottom: 10px;
  }
}
