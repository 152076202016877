// *,
// *::after,
// *::before {
//     -moz-box-sizing: border-box;
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
// }
//
// a {
//     text-decoration: none;
//     -webkit-transition: all 0.3s ease;
//     -moz-transition: all 0.3s ease;
//     -ms-transition: all 0.3s ease;
//     -o-transition: all 0.3s ease;
//     transition: all 0.3s ease;
// }

body {
    // font-family: 'Titillium Web', sans-serif;
    // font-size: 14px;
    // margin: 0;
    // padding: 0;
    // padding-top: 130px;
    // background: #EAEDED;
}

// #page, .section.roadmap {
//     overflow: hidden;
// }

.container,
.frst-container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}

.frst-container {
    /*-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;*/
    margin: 90px auto;
}

.mobile-window .frst-container {
    max-width: 678px;
}

.tablet-window .frst-container {
    max-width: 992px;
}

.frst-container.content-right-align,
.frst-container.content-left-align {
    max-width: 678px;
}

.clearfix::after,
.container::after {
    content: "";
    display: table;
    clear: both;
}

.site-header {
    background: #00BEA4;
    color: #fff;
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 1000;
    top: 0;
}

.header-top {
    padding: 10px 0;
    text-align: center;
}

.frst-logo {
    float: left;
    padding-left: 70px;
    background: url(../../images/logo.png) no-repeat 0 -7px;
    background-size: 70px 70px;
    line-height: 1.2;
    text-align: left;
}

.frst-logo strong {
    display: block;
    font-size: 20px;
}

.button {
    display: inline-block;
    padding: 7px 16px;
    border: 1px solid #fff;
    color: #fff;
    font-weight: bold;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.button:hover {
    color: #8541c0;
    background: #fff;
    text-decoration: none;
}

.header-top .button {
    float: right;
    margin-top: 3px;
}

.style-switch {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 3px 0 0;
    float: right;
}

.style-switch li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px;
    font-size: 16px;
}

.style-switch a {
    color: rgba(255, 255, 255, 0.5);
    display: block;
    padding: 7px 5px;
}

.style-switch a:hover {
    color: #fff;
}

.style-switch a.switch-btn {
    background: #017c73;
    padding: 10px 50px 10px 30px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    position: relative;
}

.style-switch a.switch-btn::after {
    content: "";
    border-width: 6px 6px 0;
    border-style: solid;
    border-color: #fff transparent transparent;
    position: absolute;
    right: 18px;
    top: 50%;
    margin-top: -3px;
}

.header-style-options {
    text-align: center;
    font-size: 0;
    padding-top: 30px;
    display: none;
}

.style-list-main {
    margin: 0 -15px;
}

.style-list {
    padding: 0 15px;
    margin-bottom: 20px;
    list-style: none;
    max-width: 800px;
    margin: 0 auto;
}

.style-list li {
    padding-bottom: 10px;
    display: inline-block;
}

.style-list a {
    display: block;
    font-size: 16px;
    width: 140px;
    padding: 6px 12px;
    color: #fff;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-align: left;
}

.style-list a.active {
    background: #5a2b82;
}

.style-list a:hover {
    background: #017c73;
}

.header-cutomizations {
    background: #00BEA4;
    padding: 15px 0;
}

.demo-label {
    float: left;
    line-height: 36px;
    color: #535353;
    font-weight: bold;
}

.demo-label span {
    display: inline-block;
    background: #054e77;
    color: #fff;
    text-transform: uppercase;
    padding: 0 12px;
    margin-right: 3px;
    border-radius: 20px;
}

.custom-options {
    overflow: hidden;
    width: 60%;
    margin: 0 auto;
    min-height: 60px;
}

.button-group {
    float: left;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
    margin-left: 20px;
    border: 1px solid #116b9e;
}

.button-group li {
    display: inline-block;
    border-right: 1px solid #d7d7d7;
}

.button-group li:last-child {
    border-right: none;
}

.button-group li.active {
    border-right-color: #054e77;
}

.button-group a {
    display: block;
    padding: 8px 16px;
    background: transparent;
    font-size: 14px;
    color: #ffffff;
    line-height: 19px;
    border: 1px solid #ffffff;
}

.button-group a:hover {
    color: #eff4f5;
}

.button-group .active a {
    color: #fff;
    background: #017c73;
}

.button-group svg {
    max-width: 19px;
    height: auto;
    display: block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.button-group a:hover svg {
    fill: #8541c0;
}

.button-group .active svg path {
    fill: #fff;
}

.animation-selector {
    float: left;
    margin-left: 20px;
}

.animation-selector select {
    height: 36px;
    border: 1px solid #d7d7d7;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: #fff url(../../images/drop-arrow.gif) no-repeat right 0;
    color: #444;
    font-size: 14px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: normal;
    padding: 0 14px;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    width: 190px;
}

.animation-selector select optgroup {
    font-family: 'Titillium Web', sans-serif;
}

.animation-selector select::-ms-expand {
    display: none;
}

.check-style {
    position: relative;
    float: left;
    margin-left: 20px;
    color: #fff;
    line-height: 20px;
    margin-top: 8px;
}

.check-style input {
    position: absolute;
    opacity: 0;
    width: 20px;
    height: 100%;
}

.check-style span {
    display: block;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #054e77;
    border-radius: 2px;
    cursor: pointer;
    float: left;
    margin-right: 6px;
    position: relative;
}

.check-style span::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    background: url(../../images/tick.png) no-repeat center center;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.check-style input:checked~span::after {
    opacity: 1;
}

.transition-ease {
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.effect1,
.effect2 {
    position: relative;
}

.effect1:before {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 25px;
    left: 2px;
    width: 90%;
    max-height: 80%;
    top: 9%;
    max-width: 480px;
    background: rgba(210, 210, 210, 0.23);
    -webkit-box-shadow: 0 35px 20px #ababab;
    -moz-box-shadow: 0 35px 20px #ababab;
    box-shadow: -3px 35px 20px #ababab;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
}

.effect1:after {
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    -o-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
    right: 10px;
    left: auto;
}

.effect2:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 25px;
    left: 30px;
    width: 88%;
    max-height: 80%;
    top: 9%;
    right: 30px;
    max-width: 480px;
    background: rgba(210, 210, 210, 0.23);
    -webkit-box-shadow: 0 35px 20px #ababab;
    -moz-box-shadow: 0 35px 20px #ababab;
    box-shadow: 6px 35px 20px #ababab;
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    -o-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    transform: rotate(4deg);
}

.icons-link {
    float: left;
    margin-left: 30px;
    font-size: 28px;
    position: relative;
    top: 0px;
    border: 2px solid #009688;
    padding: 2px 12px;
    border-radius: 30px;
    background: #009688;
    transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1);
}

.icons-link:hover {
    box-shadow: 0 16px 26px -10px rgb(0, 150, 136), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 150, 136, 0.2);
}

.icons-link a {
    color: white;
    position: relative;
    top: 2px;
}

.icons-link span {
    font-size: 16px;
    position: relative;
    top: -7px;
}

@media only screen and (max-width:1066px) {
    .custom-options {
        width: 80%;
    }
}

@media only screen and (max-width:800px) {
    // body {
    //     padding-top: 65px;
    // }
    .header-style-options {
        text-align: left;
    }
    .header-style-options ul:empty {
        display: none;
    }
    .header-cutomizations,
    .frst-logo,
    .header-top .button {
        display: none;
    }
    .style-switch a.switch-nav {
        width: 150px;
        text-align: left;
        padding: 10px 5px;
    }
    .style-switch a.switch-prev {
        text-align: right;
    }
}

@media (max-width:525px) {
    .style-switch li {
        margin: 0 0px;
    }
}

@media only screen and (max-width:490px) {
    .style-switch a.switch-nav {
        width: 80px;
    }
}
