
/* PARTICLE */

#particles-js .particles-js-canvas-el {
  // position: absolute;
  // z-index: 0;
}
// .main-container {
//   position: relative;
//   z-index: 2;
// }


.imgcenter {
  margin: 0 auto;
}
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  // background-color: rgba(255,0,0,0.5);
  // background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  transform: translate(0);
  -moz-transform: translate(0);
  -ms-transform: translate(0);
  -webkit-transform: translate(0);
}

//
// .main-container{
//   background:#00f;
//   text-align:center;
//   padding:40px;
// }

.main-container a{
  position:relative;
  z-index:9999;
  // color:#fff;
  // font-size:32px;
}
.whitebg {
  padding-top: 15px;
  background-color: #ffffff;
}



#tbLanguageWidget {
  margin-right: 116px !important;
}
/*HELPERS*/
.showru, .showjp {
  display:none;
}
// .showru {display: none!important;}
.no-margin{margin:0 !important;}
.no-pad-left {padding-left:0;}
.preicoactive,  .prepreico, .nav>li.prepreico, .nav>li.preicoactive{
  display:none;
}
.padd-top {padding-top:20px;}

// .topalert.alert:after {
// top:3px;
// }
// .topalert {
//   padding-top:6px;
//   padding-bottom:5px;
// }
// .progressbar .btn {
//   padding-top:6px;
//   padding-bottom:5px;
// }
.progress-bar {
  padding:10px 0 10px 0;
  font-size: 18px;
  color:#000000;
  font-weight: 400;
}
.progress {
      height: 43px;
}

.ethRaiseBar {
  position: absolute;
  width: 94%;
}
// .progress-bar span {
//   left:auto;
// }


.countdown.is-countdown {line-height: 0;}


.countdown-amount {
  font-size:20px;
}
.countdown-period {
  font-size:12px;
}
.navbar-default .navbar-nav>li.cta>a{
  background-color: transparent;
      color: #e09200;
      border: 1px solid #F18F33;
      font-weight: 300;
}
.sticky-social .social-links li {
  display:block;
  a{
    background: #333;
      border: 1px solid #ffffff;
      color: #fff;
  }
}
.sticky-social {
  position: fixed;
    right: 0;
    z-index: 99;
  bottom:0;
}
.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6{font-family:inherit;font-weight:300;}


.highlightdelight {
  background-color: transparent;
color: #e09200;
border: 1px solid #F18F33;
font-weight: 300;
}



.image-box.team-member {
  max-width: 268px;

}
.partners img {
  max-height: 70px;
}
@media (min-width:992px) {
  .image-box.team-member{
    &.team-right{
      float:right;
    }
    &.team-mid
      {
        margin:0 auto;
      }
  }

}

/*about  */
.lead-2 {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.57;
}
@media (min-width: 768px) {
  .lead-2 {
    font-size: 18px;
  }
}
@media (min-width: 768px)and (max-width:991px) {

  .right-mid {
    float:right;
  }
}
.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead {
    font-size: 18px;
  }
}



/* HEADER HERO*/
.home h1 {
  text-transform: uppercase;
}
// .header {
//   height:10vh;
// }
// .home .main-container {
//   padding:0;
//   height:90vh;
//   display: flex;
//     flex-direction: column;
//     justify-content: center;
//     overflow: auto;
//     margin-top: 0px;
//     .hero {
//       overflow: auto;
//     }
// }
// .fixed-header-on .header.fixed {
//   height:auto;
// }
// .header.object-non-visible  {
//   display: none;
// }
// .header.animated.object-visible {
//   display: block !important;
// }



.header {
  height:10vh;
}
.home .main-container {
  padding:0;
  padding-top:9vh;
  margin-bottom:10vh;
  height:68vh;
  // display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   overflow: auto;
  //   margin-top: 0px;
  //   .hero {
  //     overflow: auto;
  //   }
}
.fixed-header-on .header.fixed {
  height:auto;
}

.sticky-social .social-links li a {
  background: none;
  border: 0!important;
  color: #000;

}
.social-links li.socialicon {
  margin:0;
}
.sticky-social {
  top: auto;
  bottom:70px;
}

.partners div{
  padding: 20px 0;
}

@media only screen and (min-width:992px) {

  .logo {
    float:left;
  }
  .countdown {
    width: 67%;
    margin: 0 auto;
  }
  .join-ico .btn {
        float: right;
  }
}
@media only screen and (max-width:991px) {
  .partners div img {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .header {
    height:14vh;
  }
  .home .main-container {
    height:86vh;
        height:90vh;
  }
  .navbar-default .navbar-nav>li.cta>a{
        padding: 8px;
  }
  h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width:767px) {

  .header {
    height:10vh;
  }
  // .main-container {
  //   height:90vh;
  // }
  // .header .header-right {
  //   display: none;
  // }
  .page-wrapper .header {
    padding-top: 0;
    margin-top: 0;
  }
  header.header {
    position: fixed;
background: white;
z-index: 999;
right: 5px;
border: none;
width: 100%;
  }
  .navbar-toggle{
    position: absolute;
top: 5px;
z-index: 999;
right: 0;
  }
  .header.object-non-visible  {opacity: 1;}

  .nav-right {
    position: fixed;
    background: white;
    z-index: 999;
    right: 0px;
    border: none;
    width: 100%;
  }
  .navbar.navbar-default {
    background: #fff;
        border: none;
  }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form{
    border:none;
  }
  .navbar-default .navbar-collapse{

    padding-top: 12px;
  }
  .navbar-default .navbar-toggle, .icon-bar, .navbar-default button.navbar-toggle {

            border-color: #F18F33;
  }
.navbar-default .navbar-toggle .icon-bar {
      background-color: #F18F33;
}

  .join-ico {
        text-align: center;
  }
  header.header #logo {
    margin: initial;
  }
 .navbar.navbar-default .container-fluid {
   padding-left:0;
 }
 .main-container .x-btns .call-to-action {
   padding-bottom: 0;
 }
 .call-to-action .btn.prototypelink {
   margin-bottom: 0;
 }
   .navbar-collapse .cta .joinico{margin-left: 15px;}
}

@media only screen and (max-width:460px) {
  .icon-container {
    display: none;
  }
}
@media (min-width:380px)and (max-width:591px) {
  // .image-box.team-member {min-height: 711px;}
  .section.team .col-xs-6{
    width:100%;
  }
  .image-box.team-member{
  min-height: auto;}
}
